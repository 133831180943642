.ListenContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.CifraResponse {
    z-index: -900;
    height: 0px;
    color: transparent;
}
.CopyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.TitleWaiting {
    height: 29px;
    color: #FFFFFF;
    opacity: 0.5;
    font-family: Raleway;
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
    width: 100%;
    text-align: center;
}
.TitleReady {
    height: 29px;
    color: #FFFFFF;
    opacity: 1;
    font-family: Raleway;
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
    width: 100%;
    text-align: center;
}
.Description {
	height: 20px;
	opacity: 0.87;
	color: #FFFFFF;
	font-family: Raleway;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0.29px;
	line-height: 20px;
	text-align: center;
}