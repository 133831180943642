body {
  margin: 0;
  font-family: 'Raleway', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(135deg, #181B8F 0%, #02B4FF 100%);
  background-repeat: no-repeat;
  background-attachment: fixed
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
