.QuizAnswerContainer {
    display: flex;
    flex-direction: row;
    font-size: 24px;
	font-weight: bold;
    align-items: center;
    width: 280px;
    justify-content: flex-start;
    padding-left: 21px;
    margin-top: 5px;
    height: 62px;
    border: 2px solid #E9E9E9;
	border-radius: 35px;
    cursor: pointer;
}
.Letter {
    padding-right: 21px;
}
.Ansrow {
    display: flex;
    flex-direction: row;
    width: 100%;
}
