.DoneContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 80vh;
    color: #FFFFFF;
    font-family: Raleway;
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
    text-align: center;
}

.ThanksTitle {
    margin-bottom: 48px;
}