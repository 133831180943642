.App {
  text-align: center;
  background: linear-gradient(135deg, #181B8F 0%, #02B4FF 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.screenvision--modal {
  position: relative;
  z-index: 500;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  background-color: #FFFFFF;
  border-radius: 12px;
  width: 85%;
  min-width: 300px;
  max-width: 450px;
}
.quiz--modal {
  position: relative;
  margin-top: 45px;
  z-index: 500;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  background-color: #FFFFFF;
  border-radius: 12px;
  height: 550px;
}

.screenvision--modal__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.screenvision--modal__image {
  margin: 40px 0;
}

.action--row {
  width: 100%;
}
.commom--blue__fab {
  background-color: #02B4FF;
  color: white;
  width: 100% !important;
}
.commom--blue__fab__container {
  margin: 24px;
}
.screenvision--modal__banner {
  height: 72px;
  width: 260px;
  color: #000000;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  
}
.button-container .pulse {
  height: 200px;
  width: 200px;
  border-radius: 200px;
  &:disabled {
    background-color: white !important;
  }
}
.not-selected {
  background-color: #ffffff;
}
.quiz--answer--selected {
  background-color: #8DD394 !important;
  color: #000000 !important;
}
.quiz--answer--wrong {
  background-color: #FFADAD !important;
}
.greyText {
  color: #37394C;
}
.pulse {
  -webkit-animation: ripple 0.7s linear infinite;
  animation: ripple 0.7s linear infinite;
}
@-webkit-keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(4,52,119, 0.3), 0 0 0 1em rgba(4,52,119, 0.3), 0 0 0 3em rgba(4,52,119, 0.3), 0 0 0 5em rgba(4,52,119, 0.3);
  }
  100% {
    box-shadow: 0 0 0 1em rgba(4,52,119, 0.3), 0 0 0 3em rgba(4,52,119, 0.3), 0 0 0 5em rgba(4,52,119, 0.3), 0 0 0 8em rgba(4,52,119, 0);
  }
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(4,52,119, 0.3), 0 0 0 1em rgba(4,52,119, 0.3), 0 0 0 3em rgba(4,52,119, 0.3), 0 0 0 5em rgba(4,52,119, 0.3);
  }
  100% {
    box-shadow: 0 0 0 1em rgba(4,52,119, 0.3), 0 0 0 3em rgba(4,52,119, 0.3), 0 0 0 5em rgba(4,52,119, 0.3), 0 0 0 8em rgba(4,52,119, 0);
  }
}