.QuizModalContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
}

.QuizContainer {
    display: flex;
    align-items: center;
    width: 100%;
}

.QuizTitleContainer {
    box-sizing: border-box;
    height: 42px;
    width: 152px;
    border: 2px solid #E9E9E9;
    border-radius: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 18px;
}

.QuizTitle {
    height: 29px;
    width: 105px;
    color: #888888;
    font-family: Raleway;
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
    text-align: center;
}

.WrongTitleContainer {
    box-sizing: border-box;
    height: 40px;
    width: 150px;
    border-radius: 35px;
    background-color: #FFADAD;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 18px;
}

.WrongTitle {
    height: 29px;
    color: #FFFFFF;
    font-family: Raleway;
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
    text-align: center;
}

.NoAnswerContainer {
    height: 40px;
    width: 150px;
    border-radius: 35px;
    background-color: #B6B6B6;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 18px;

}

.CorrectTitleContainer {
    height: 40px;
    width: 150px;
    border-radius: 35px;
    background-color: #8DD394;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 18px;
}

.QuizQuestion {
    margin: 18px;
    color: #000000;
    font-family: Raleway;
    font-size: 20px;
    font-weight: bold;
    line-height: 36px;
}

.AnswerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}